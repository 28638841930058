<template>
  <div
    class="relative banner group"
    :class="
      isProductPage
        ? 'mt-[-1rem]'
        : props.isMainBanner
        ? 'pt-[64px] lg:pt-[96px]'
        : 'mt-0  mx-4 lg:mx-0'
    "
    :style="styles"
  >
    <div
      v-if="props.overlay"
      class="absolute inset-0 z-30 bg-black opacity-50"
    />
    <!-- image as background -->
    <template v-if="props.imageAsBackground === true && props.image">
      <div
        v-show="!isImageLoaded"
        class="!z-20 banner__background filter blur-0 bg-black"
      ></div>
      <NuxtImg
        :alt="props.headline"
        :src="props.image"
        format="webp"
        width="100%"
        height="100%"
        fit="crop"
        :sizes="
          props.multiple
            ? 'xs:100vw sm:100vw md:100vw lg:50vw'
            : 'xs:100vw sm:100vw md:100vw lg:100vw'
        "
        :preload="props.areaIndex < 2 && props.itemIndex < 2"
        :loading="props.areaIndex < 2 && props.itemIndex < 2 ? '' : 'lazy'"
        @load="onLoadImage"
        class="banner__background"
      />
      <div class="banner__cover" />
    </template>
    <template v-if="props.backgroundVideo">
      <video
        ref="backgroundVideo"
        loop
        preload="metadata"
        playsinline
        muted
        loading="lazy"
        class="absolute inset-0 z-10 object-cover w-full max-w-full min-w-full min-h-full"
      >
        <source :src="props.backgroundVideo" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </template>
    <div
      class="banner__container"
      :class="props.productSection ? 'product_page_container' : '  z-30'"
    >
      <!-- <XyzTransition :appear-visible="appearVisible" duration="auto"> -->
      <div
        class="banner__inner"
        :class="[
          props.productSection
            ? 'h-auto pb-0 product-page'
            : props.isMainBanner
            ? 'h-[416px] md:h-[484px] xl:h-[624px]'
            : 'h-[480px] md:h-[600px] xl:h-[740px] ',
          !props.productSection ? middleContentAlignment : '',
        ]"
      >
        <div xyz="fade small stagger ease-out-back" :class="textAlignmentClass">
          <component
            :is="props.headlineTag"
            :style="
              props.textShadows && props.isTextShadow
                ? `text-shadow: ${props.textShadows.x_offset}px ${props.textShadows.y_offset}px ${props.textShadows.blur}px ${props.textShadows.converted_color}`
                : ''
            "
            class="mb-2 md:mb-1 font-universe-lt-std xyz-nested 2xl:px-4"
          >
            <slot name="headline">
              {{ props.headline }}
            </slot>
          </component>
          <div
            v-if="props.subheadline || hasSlot('subheadline')"
            :style="
              props.textShadows && props.isTextShadow
                ? `text-shadow: ${props.textShadows.x_offset}px ${props.textShadows.y_offset}px ${props.textShadows.blur}px ${props.textShadows.converted_color}`
                : ''
            "
            class="px-8 text-2xl font-light leading-none tracking-wide font-universe-lt-std xyz-nested md:text-3xl xl:text-4xl"
          >
            <slot name="subheadline">
              {{ props.subheadline }}
            </slot>
          </div>

          <div class="mt-1 banner__links">
            <a
              v-for="(link, linkIndex) in props.links"
              :key="linkIndex"
              :href="link.to"
              class="font-light xyz-nested font-universe-lt-std"
              :style="
                props.textShadows && props.isTextShadow
                  ? `text-shadow: ${props.textShadows.x_offset}px ${props.textShadows.y_offset}px ${props.textShadows.blur}px ${props.textShadows.converted_color}`
                  : ''
              "
              v-text="link.text"
            />
          </div>
        </div>
        <a
          v-if="props.video"
          :href="'#'"
          class="absolute transform -translate-s-1/2 start-1/2 bottom-[50%]"
          style="filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.8))"
          @click.prevent="openVideoModal"
        >
          <YoutubeIcon class="w-12 h-12 text-white fill-current" />
        </a>

        <NuxtImg
          v-if="!props.imageAsBackground && props.image"
          :src="props.image"
          format="webp,avif"
          fetchpriority="high"
          loading="lazy"
          width="100%"
          height="100%"
          preload
          :alt="props.headline"
          :class="[
            'mx-auto mt-8 banner__image',
            props.productSection ? 'w-full' : 'md:w-[60%] w-fit lg:w-full',
          ]"
        />

        <span />
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
import YoutubeVideoModal from '~/modals/YoutubeVideoModal'
import { nextTick } from 'vue'
import { useModal } from 'vue-final-modal'
import YoutubeIcon from '~/assets/svg/heroicons/youtube.svg'
import { ContentLoader } from 'vue-content-loader'

const props = defineProps({
  areaIndex: {
    type: Number,
    default: false,
  },
  itemIndex: {
    type: Number,
    default: false,
  },
  isMainBanner: {
    type: Boolean,
    default: false,
  },
  headlineTag: {
    type: String,
    default: 'h4',
  },
  appearVisible: {
    type: Boolean,
    default: false,
  },
  headline: {
    type: String,
    default: null,
  },
  subheadline: {
    type: String,
    default: null,
  },
  links: {
    type: Array,
    default: () => [],
  },
  image: {
    type: String,
    default: null,
  },
  imageAsBackground: {
    type: Boolean,
    default: false,
  },
  // @TODO: temporary, remove and use video
  backgroundVideo: {
    type: String,
    default: null,
  },
  video: {
    type: String,
    default: null,
  },
  colors: {
    type: Object,
    default: () => ({
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, #3a3939 100%)',
      text_color: '#FFFFFF',
    }),
  },
  productSection: {
    type: Boolean,
    default: false,
  },
  overlay: {
    type: Boolean,
    default: false,
  },
  position: {
    type: String,
    default: 'center_middle',
  },
  isTextShadow: {
    type: Boolean,
    default: false,
  },
  textShadows: {
    type: Object,
    default: () => {},
  },
})

const slots = useSlots()
const route = useRoute()
const isImageLoaded = ref(false)
const isProductPage = ref(false)

isProductPage.value = !!(route.name == 'country-products-slug')

const styles = computed(() => {
  return {
    background: props.colors.background,
    color: props.colors.text_color,
  }
})

const textAlignmentClass = computed(() => {
  if (!props.imageAsBackground) return 'text-center'

  switch (props.position) {
    case 'left_top':
      return 'text-left'
    case 'left_middle':
      return 'text-left w-[430px]'
    case 'left_bottom':
      return 'text-left'
    case 'center_top':
      return 'text-center'
    case 'center_middle':
      return 'text-center'
    case 'center_bottom':
      return 'text-center'
    case 'right_top':
      return 'text-right'
    case 'right_middle':
      return 'text-right w-[430px]'
    case 'right_bottom':
      return 'text-right'

    default:
      return 'text-center'
  }
})

const middleContentAlignment = computed(() => {
  if (!props.imageAsBackground)
    return 'flex justify-center items-center flex-col pt-[64px] md:pt-[80px] '

  switch (props.position) {
    case 'left_top':
      return 'flex flex-col justify-start rtl:items-end ltr:items-start pt-16 md:pt-10'
    case 'left_middle':
      return 'flex flex-col justify-center rtl:items-end ltr:items-start'
    case 'left_bottom':
      return 'flex flex-col justify-end rtl:items-end ltr:items-start pb-[64px] md:pb-[80px]'
    case 'center_top':
      return 'flex flex-col justify-start items-center pt-16 md:pt-10 '
    case 'center_middle':
      return 'flex flex-col justify-center items-center'
    case 'center_bottom':
      return 'flex flex-col justify-end  items-center pb-[64px] md:pb-[80px]'
    case 'right_top':
      return 'flex flex-col justify-start rtl:items-start ltr:items-end pt-16 md:pt-10'
    case 'right_middle':
      return 'flex flex-col justify-center rtl:items-start ltr:items-end'
    case 'right_bottom':
      return 'flex flex-col justify-end rtl:items-start ltr:items-end pb-[64px] md:pb-[80px]'

    default:
      return 'flex flex-col justify-start items-center pt-16 md:pt-10 '
  }
})

onMounted(async () => {
  await nextTick()
  if (process.browser) {
    const videos = document.querySelectorAll('video')

    if (videos.length >= 1) {
      const options = {
        rootMargin: '0px 0px -20% 0px',
      }

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          // eslint-disable-line arrow-parens
          if (entry.isIntersecting) {
            entry.target.play()
          } else {
            entry.target.pause()
          }
        })
      }, options)

      Array.prototype.forEach.call(videos, (video) => {
        // eslint-disable-line arrow-parens
        observer.observe(video)
      })
    }
  }
})

const { open, close } = useModal({
  component: YoutubeVideoModal,
  attrs: {
    source: props.video,
  },
})
const openVideoModal = open

function onLoadImage(result) {
  if (result) {
    isImageLoaded.value = true
  }
}
function hasSlot(name = 'default') {
  return !!slots[name] || !!slots.default[name]
}
</script>

<style lang="postcss">
.banner {
  @apply flex relative text-center overflow-hidden;

  video {
    aspect-ratio: 16/9;
  }
  h1 {
    @apply text-4xl font-medium tracking-wider md:tracking-wide sm:text-[40px] leading-none md:text-5xl xl:text-6xl 2xl:text-7xl;
  }
  h2 {
    @apply text-3xl font-medium tracking-wider md:tracking-wide  sm:text-[40px] leading-none 2xl:leading-tight md:text-5xl xl:text-6xl 2xl:text-7xl;
  }
}

.banner__container {
  @apply w-1/2 mx-auto z-20 relative;
}

.banner__inner {
  @apply overflow-hidden pt-16 md:pt-24 md:pb-5 lg:pb-12 flex flex-col justify-between;
}

.banner__cover {
  @apply absolute inset-0 z-10 w-full h-full;
}

.banner__coverlink {
  @apply absolute top-0 bottom-0 end-0 start-0 flex items-center justify-center text-white z-10;
}

.banner__links {
  @apply space-s-6;
}

.banner__links a {
  @apply text-xl lg:text-2xl relative tracking-wide z-20 text-current font-normal font-universe-lt-std;
}

.banner__image {
  @apply max-w-full h-auto;
}

.banner__background {
  @apply absolute inset-0 w-full h-full object-cover z-10;
}

@media only screen and (max-width: 1920px) {
  .banner__container {
    width: 90%;
  }
}

@media only screen and (max-width: 1024px) {
  .banner__container {
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .banner__container {
    @apply w-full;
  }
}

@media only screen and (max-width: 425px) {
  .banner__container {
    @apply w-full;
  }
}
</style>
